import React from 'react';
import { graphql } from 'gatsby';
import { RichText } from 'prismic-reactjs';
import _get from 'lodash/get';

import Layout from '@components/Layout';
import Images from '@components/Images';

import { linkResolver } from '@utils/Prismic/linkResolver';
import { extractGraphqlData } from '@utils/extractors';
import { ENUM_STATIC_MENU_LINKS } from '@utils/types';

interface IExpertKnowledgeProps {
  data: {
    prismic: {
      allExpert_knowledge_pages: {
        edges: {
          node: any;
        }[];
      };
    };
  };
}

const ExpertKnowledge: React.FC<IExpertKnowledgeProps> = ({ data }) => {
  const doc = extractGraphqlData(data, 'allExpert_knowledge_pages');
  if (!doc) return null;

  const { page_title, page_description, canonical_tag, page_og_image } = doc.node.seo[0];

  return (
    <>
      <Layout
        title={page_title ? RichText.asText(page_title) : 'Expert Knowledge'}
        description={page_description && RichText.asText(page_description)}
        firstLevelPage
        seo={{
          path: ENUM_STATIC_MENU_LINKS['EXPERT KNOWLEDGE'],
          canonical: canonical_tag && canonical_tag.url,
          image: page_og_image ? page_og_image : _get(doc, ['node', 'image_right', 'url']),
        }}
      >
        <div className="knowledge">
          <div className="knowledge__content">
            <div className="knowledge__text">
              <div className="knowledge__text-inner">
                <div className="anim-block">
                  <h1>{RichText.asText(doc.node.heading)}</h1>
                  {RichText.render(doc.node.description_text, linkResolver)}
                </div>
              </div>
            </div>
            <div className="knowledge__image">
              <Images
                imageData={[{'image': doc.node.image_right}]}
                extractPath="image"
                alt={_get(doc, ['node', 'image_right', 'alt'])}
                classes={{
                  base: 'anim-image',
                  extend: 'knowledge',
                  wrap: 'knowledge__image-img',
                  class: ''
                }}
                width="526"
              />
            </div>
            <div className="knowledge__panoramic">
              <Images
                imageData={[{'image': doc.node.image_below}]}
                extractPath="image"
                alt={_get(doc, ['node', 'image_below', 'alt'])}
                classes={{
                  base: 'anim-bottom-image',
                  extend: 'knowledge',
                  wrap: 'knowledge__panoramic-img',
                  class: ''
                }}
                width="1314"
                setHeight={false}
              />
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export const query = graphql`
  query ExpertKnowledge {
    prismic {
      allExpert_knowledge_pages {
        edges {
          node {
            heading
            description_text
            image_right
            image_below
            seo {
              page_title
              page_description
              page_og_image
              canonical_tag {
                ... on PRISMIC__ExternalLink {
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`;
export default ExpertKnowledge;
